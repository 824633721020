@use "sass:meta" as ---p0dxu0oa6o;// Add padding in the first slice only if it's not full-bleed
.story-group-slice:first-of-type .slice:not(.slice--full-bleed) {
	@media (min-width: $desktop-breakpoint) {
		padding-top: $section-vertical-spacing;
	}
}

@mixin hidePrimaryStoryImageOnlyMobile {
	.primary-story__image {
		display: none;

		@media (min-width: $desktop-breakpoint) {
			display: block;
		}
	}
}

// Hide images on Tablet/Mobile
// Images that should not be suppressed:
// - Any images in any Top Slice layout (Exception: Breadth extra small & Normal small StroyGroup images)
// - Primary Story in StroyGroups with “Feature treatment” (Spark toggle)
// - Primary Story in first StroyGroup in each Slice

.story-group-slice {
	// Apply for non Top Slices
	.slice.top-stories-slice:not(.story-group-named-slice) {
		// hide Primary Story images except StroyGroups with “Feature treatment” (Spark toggle) & first StroyGroup on the Slice
		.layout-desktop__grid-container .layout-desktop__grid:not(:first-child) {
			.story-group:not(.story-group--featured) {
				@include hidePrimaryStoryImageOnlyMobile;

				// add top padding to Primary Story teasers whose image is hidden
				.primary-story__teaser {
					padding-top: $vertical-spacing;

					@media (min-width: $desktop-breakpoint) {
						padding-top: 0;
					}
				}
			}
		}

		// hide Spotlight's & Opinion second story image
		.layout-desktop__grid-container .layout-desktop__grid:first-child {
			.spotlight-grid--secondary-story,
			.main-opinion-stories__secondary-story {
				.primary-story__image {
					display: none;

					@media (min-width: $desktop-breakpoint) {
						display: block;
					}
				}
			}
		}
	}

	// Apply for Top Slices
	.slice.story-group-named-slice {
		// hide Breadth extra small & Normal small StroyGroup images on Top Slices
		div[data-trackable-context-storygroup-size='small'],
		div[data-trackable-context-storygroup-size='xsmall'] {
			.story-group:not(.story-group--featured) {
				@include hidePrimaryStoryImageOnlyMobile;
			}
		}
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:318");