@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.layout-desktop__grid-container {
	display: grid;
	margin-left: -$horizontal-spacing;
	margin-right: -$horizontal-spacing;

	@media (min-width: $desktop-breakpoint) {
		grid-template-columns: repeat(12, 1fr);
	}
}

.layout-desktop__grid-container--9-columns {
	@media (min-width: $desktop-breakpoint) {
		grid-template-columns: repeat(9, 1fr);
	}
}

.layout-desktop__grid {
	display: flex;
	flex-direction: column;
}

// add vertical margin between grids (storygroups) on mobile
.slice:not(.story-group-named-slice) {
	.layout-desktop__grid + .layout-desktop__grid {
		margin-top: $vertical-spacing;

		@media (min-width: $desktop-breakpoint) {
			margin-top: 0;
		}
	}
	.opinion-section .layout-desktop__grid + .layout-desktop__grid {
		margin-top: 0;
	}
}

.layout-desktop__grid--with-padding {
	.separator--solid {
		margin: $vertical-spacing $horizontal-spacing;
	}

	.separator--dotted {
		margin: $section-vertical-spacing $horizontal-spacing;
	}
}

.layout-desktop__grid--with-border {
	.layout__grid-content {
		@media (min-width: $desktop-breakpoint) {
			border-right: solid oColorsByName('black-10') 1px;
		}
	}
	&.layout--impact {
		.layout__grid-content {
			@media (min-width: $desktop-breakpoint) {
				border-right: solid oColorsByName('black-30') 1px;
			}
		}
		& .stacked-secondary-story {
			border-left: 1px solid oColorsByName('black-30');
		}
	}
}

.layout--impact {
	& .separator--solid {
		border-bottom: 1px solid oColorsByName('black-30');
	}
}

.story-group-named-slice .layout-desktop__grid--with-border {
	border-bottom: 1px solid oColorsByName('black-10');
	margin-bottom: $vertical-spacing * 2;

	&.layout--impact {
		border-bottom: 1px solid oColorsByName('black-30');
	}
	&:not(:first-child) {
		padding-bottom: $vertical-spacing * 2;
	}

	@media (min-width: $desktop-breakpoint) {
		border-bottom: none;
		margin: 0;

		&:not(:first-child) {
			padding: 0;
		}

		&.layout--impact {
			border-bottom: none;
		}
	}
}

@for $i from 1 through 12 {
	.layout-desktop__grid--column-start-#{$i} {
		@media (min-width: $desktop-breakpoint) {
			grid-column-start: $i;
		}
	}
}

@for $i from 1 through 14 {
	.layout-desktop__grid--row-start-#{$i} {
		@media (min-width: $desktop-breakpoint) {
			grid-row-start: $i;
		}
	}
}

@for $i from 1 through 12 {
	.layout-desktop__grid--span#{$i} {
		@media (min-width: $desktop-breakpoint) {
			grid-column: span $i;
		}
	}
}

.layout-desktop--full-height {
	height: auto;

	@media (min-width: $desktop-breakpoint) {
		height: 1px; /* IE 11 hack to allow Flex items to 100% height */
		height: 100%;
	}
}

.layout-desktop__grid--hidden {
	display: none;
}

.layout__grid-content {
	flex-grow: 1;
	@media (min-width: $desktop-breakpoint) {
		padding-left: $horizontal-spacing;
		padding-right: $horizontal-spacing;
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:315");