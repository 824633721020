@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.separator--solid {
	display: flex;
	border-bottom: 1px solid oColorsByName('black-10');
}

.separator--dotted {
	background: linear-gradient(90deg, oColorsByName('paper') 2px, transparent 1%) center,
		linear-gradient(oColorsByName('paper') 2px, transparent 1%) center,
		black;
	background-position: 0 0;
	background-size: 3px 3px;
	height: 9px;
	width: 100%;

	// These classes are only applied on IE
	// This is a temporary solution until we confirm whether the below style works on IE
	// background-image: radial-gradient(#212121 20%, transparent 20%);
	@media all and (-ms-high-contrast: none) {
		background-image: none;
		background-size: none;
		height: 0;
		border-bottom: 1px solid oColorsByName('black-10');
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:267");