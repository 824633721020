@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.timestamp {
	@include oLabelsTimestampContent();
	display: block;
	// Moves timestamp to the bottom when stretched modifier is used
	margin-top: auto;

	@include oLabelsIndicatorContent($opts: ('block': true));
}

.timestamp--new {
	@include oLabelsIndicatorContent($opts: ('block': true, 'modifier': 'new'));
}

.timestamp--updated {
	@include oLabelsIndicatorContent($opts: ('block': true, 'modifier': 'updated'));
}

.timestamp-prefix {
	@include oLabelsIndicatorContent($opts: ('element': 'status'));
}

.timestamp-date {
	@include oLabelsIndicatorContent($opts: ('element': 'timestamp'));
}

@mixin dark-mode-timestamp {
	.timestamp, .timestamp--updated {
		color: oColorsByName('white');
	}
}
;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:270");