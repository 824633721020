@use "sass:meta" as ---p0dxu0oa6o;.opinion-section {
	width: 100%;
	padding-left: $horizontal-spacing;
	@media (min-width: $desktop-breakpoint) {
		width: unset;
		padding-left: 0;
	}

	.spotlight-story {
		margin-bottom: $vertical-spacing;
	}

	.story-group.story-group--skip-primary-story {
		@media (min-width: $desktop-breakpoint) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.list--vertical {
		display: none;
		@media (min-width: $desktop-breakpoint) {
			display: block;
		}
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:306");