@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

$list-number-margin-left: 38px;
$list-number-pos-left: -3px;
$list-number-width: 24px;

.list {
	height: 100%;
	padding-left: $horizontal-spacing;
	padding-right: $horizontal-spacing;
	@media (min-width: $desktop-breakpoint) {
		padding: 0;
	}

	.headline {
		.text {
			white-space: normal;
		}
	}
}

.list__title {
	@include oTypographySans($scale: 2);
	color: oColorsByName('black');
	font-weight: 500;
	margin: 0 0 $vertical-spacing;
}


.list__items-wrapper {
	width: 100%;
	display: flex;
	padding-left: 0;
	margin: 0;
}

ul.list__items-wrapper {
	padding-left: 0;
	margin: 0;
	li{
		list-style-type: none;
	}
}

ol.list__items-wrapper {
	counter-reset: item;
	
	li {
		position: relative;
		width: 100%;
		display: block;

		.list__item {
			width: calc(100% - #{$list-number-margin-left});
			margin-left: $list-number-margin-left;
		}
		
		div {
			display:inline-block;
		}

		&:last-child {
			margin-bottom: 0;
			.list__item-link {
				border-bottom: none;
			}
		}

		&::before {
			content: counter(item) "";
			counter-increment: item;
			@include oTypographyDisplay($scale: ('default': 5, 'M': 6));
			color: oColorsByName('black-50');
			font-weight: 500;
			position: absolute;
			top: 50%; 
			transform: translateY(-50%);
			min-width: $list-number-width;
			max-width: $list-number-width;
			left: $list-number-pos-left;
			text-align: center;
		}
	}
}

.list__item-link {
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid oColorsByName('black-10');
	vertical-align: middle;
	padding: $vertical-spacing 0;
}

.list.list--vertical {
	.list__items-wrapper {
		flex-direction: column;
	}
}

.list.list--horizontal {
	display: none; // Horizontal lists won't be displayed on mobile
	@media (min-width: $desktop-breakpoint) {
		display: inherit;
	}

	.list__title {
		text-align: center;
	}
	.list__items-wrapper {
		flex-direction: row;
		justify-content: center;
		gap: 12px;
	}
	.list__item-link {
		border-bottom: none;
	}

	.list__item-link .text {
		@include oTypographySans($scale: -1);
		text-transform: uppercase;
		font-weight: 400;
	}
}
;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:276");