@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

$ad-medium-breakpoint: 760px;

pg-slot[data-config-key='top-desktop'] {
	display: none;

	@media (min-width: $ad-medium-breakpoint) {
		// GPT adds an inline style="display: none;" to collapsed slots,
		// Prevent CLS by forcing it to remain it open at 760px and above
		display: grid !important;
		place-content: center;
		transition: height 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
		height: 90px;

		&.pg-slot--size-970x250 {
			height: 250px;
		}
	}
}

.advert-slice {
	margin-bottom: 0;

	.layout__grid-content {
		background-color: oColorsMix(wheat, paper, 20);
	}

	.o-ads[data-o-ads-loaded] {
		padding: 2rem 0;
		max-width: 970px;
		margin: 0 auto 1.5rem auto;

		&[data-o-ads-loaded='false'] {
			padding: 0;
		}
	}

	.pg-slot--loaded {
		display: grid;
		place-content: center;
		margin-bottom: 1.5rem;
		padding: 1.5rem 0;
	}

	.pg-slot--collapsed {
		display: none;
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:317");