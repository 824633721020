@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-normalise/main';
@import '@financial-times/o-typography/main';
@import '@financial-times/o-fonts/main';

// Required by o-colors
$system-code: 'next-home-page';

@import 'o-normalise/main';
@import 'o-colors/main';
@import 'o-buttons/main';
@import 'o-icons/main';
@import 'o-typography/main';
@import 'o-fonts/main';
@import 'o-labels/main';
@import 'o-tooltip/main';

@include oTooltip();
@include oNormalise();
@include oButtons();

$o-typography-load-fonts: false;
/* prettier-ignore */
@include oFonts($opts: ('recommended': true,
		'metric': (('weight': 'medium', 'style': 'normal'))));

// Breakpoints
$desktop-breakpoint: 1024px;

// Layout
$content-column-max-width: 1260px;

// Spacing
$section-vertical-spacing: 40px;
$vertical-spacing: 0.75rem;
$horizontal-spacing: 1rem;

$feedback-tag-width: 28px;

// Atoms
@import '../Atoms/Image/Image.scss';
@import '../Atoms/Separator/Separator.scss';
@import '../Atoms/Icon/Icon.scss';
@import '../Atoms/Text/Text.scss';
@import '../Atoms/Timestamp/Timestamp.scss';

// Molecules
@import '../Molecules/Link/Link.scss';
@import '../Molecules/Metadata/Metadata.scss';
@import '../Molecules/SliceHeading/SliceHeading.scss';
@import '../Molecules/Slice/Slice.scss';
@import '../Molecules/PrimaryStory/PrimaryStory.scss';
@import '../Molecules/List/List.scss';
@import '../Molecules/SpotlightStory/SpotlightStory.scss';
@import '../Molecules/SpotlightStory/SpotlightStoryMain.scss';
@import '../Molecules/MainOpinionStories/MainOpinionStories.scss';
@import '../Molecules/MainOpinionStories/PrimaryOpinionStory.scss';
@import '../Molecules/FeaturedStory/FeaturedStory.scss';
@import '../Molecules/MarketsData/MarketsData.scss';
@import '../Molecules/Standfirst/Standfirst.scss';
@import '../Molecules/Headline/Headline.scss';
@import '../Molecules/StoryGroupTitle/StoryGroupTitle.scss';
@import '../Molecules/Promo/Promo.scss';
@import '../Molecules/Video/Video.scss';
@import '../Molecules/Podcast/Podcast.scss';
@import '../Molecules/MonetaryPolicyRadar/MonetaryPolicyRadar.scss';
@import '../Molecules/InteractiveGraphic/InteractiveGraphic.scss';
@import '../Molecules/Clip/Clip.scss';

// Organisms
@import '../Organisms/StoryGroup/StoryGroup.scss';
@import '../Organisms/StoryGroup/StoryGroupStacked.scss';
@import '../Organisms/StackedSecondaryStories/StackedSecondaryStories.scss';
@import '../Organisms/BreakingStoryGroup/BreakingStoryGroup.scss';
@import '../Organisms/SetPieceStoryGroup/SetPieceStoryGroup.scss';
@import '../Organisms/LinksPromo/LinksPromo.scss';
@import '../Organisms/StoryCollection/StoryCollection.scss';
@import '../Organisms/OpinionSection/OpinionSection.scss';
@import '../Organisms/CommunitySection/CommunitySection.scss';

// Layouts
@import '../Layouts/Layout/Layout.scss';
@import '../Layouts/Grid/Grid.scss';

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:237");