@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.stacked-secondary-story {
	.separator--solid {
		margin: $vertical-spacing 0;
	}

	// Fix last secondary article left border
	&:last-child {
		padding-bottom: 0;

		.grid--s3-spacing {
			grid-gap: 0;
		}
	}
}

@mixin displayAsStack($columnSpan) {
  grid-column: span $columnSpan;
	border-left: solid oColorsByName('black-10') 1px;
	padding: 0 $horizontal-spacing $vertical-spacing;

	.separator--solid {
		display: none;
	}

	.grid--fullHeight {
		height: auto;
	}
}

// Default display rules
// stack for mobile and desktop
@for $i from 1 through 4 {
	.stacked-secondary-story--span-#{$i} {
		// Stack for mobile
		@include displayAsStack($i);
		
		// Desktop
		@media (min-width: $desktop-breakpoint) {
			// Stack (default)
			&:not(.stacked-secondary-story__force-grid-on-desktop) {
				grid-column: span $i !important; /* override grid-column-start from IE styles */
				margin-left: 0;
			}

			// Grid (forced)
			&.stacked-secondary-story__force-grid-on-desktop {
				grid-column: span 1;
				border-left: none;
				padding: 0;
				&:not(.stacked-secondary-story--col-1) {
					margin-left: $horizontal-spacing;
				}
		
				// horizontal separator
				&:not(.stacked-secondary-story__use-vertical-separator) {
					.separator--solid {
						display: flex;
					}
				}
				
				// vertical separator 
				&.stacked-secondary-story__use-vertical-separator:not(:last-child),
				&.stacked-secondary-story__use-vertical-separator:nth-child(1), // force the border for the 1st and 2nd items
				&.stacked-secondary-story__use-vertical-separator:nth-child(2) {
					border-right: 1px solid oColorsByName('black-10');
				}
			}
		}
	}
}

// Grid styles (needed for IE 11)
@for $i from 1 through 4 {
	.stacked-secondary-story--col-#{$i} {
		// Stack for mobile and desktop
		grid-column-start: 1;

		@media (min-width: $desktop-breakpoint) {
			// Grid (forced)
			&.stacked-secondary-story__force-grid-on-desktop {
				grid-column-start: $i;
			}
		}
	}
}

@for $i from 1 through 10 {
	.stacked-secondary-story--row-grid-#{$i} {
		@media (min-width: $desktop-breakpoint) {
			// Grid for desktop (forced)
			&.stacked-secondary-story__force-grid-on-desktop {
				grid-row-start: $i;
			}
		}
	}
	.stacked-secondary-story--row-stack-#{$i} {
		// Stack for mobile
		grid-row-start: $i;

		@media (min-width: $desktop-breakpoint) {
			// Stack for desktop (default)
			&:not(.stacked-secondary-story__force-grid-on-desktop) {
				grid-row-start: $i !important; /* override .grid--1-columns:nth-child(2) */
			}
		}
	}
}

// IE11 detection
@media all and (-ms-high-contrast: none) {
	.grid__story-group-wrapper--secondary {
		height: 100%;
		padding-top: 5rem;
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:301");