@use "sass:meta" as ---p0dxu0oa6o;.video__article-wrapper {
	margin-bottom: 10px;
	padding: 0 16px 16px;
	height: fit-content;

	& .timestamp-prefix {
		display: none;
	}

	& .video__content--displayTag {
		padding-right: 20px;
		display: inline-block;
		margin-bottom: oSpacingByName('s3');
	}

	.preview__image--container {
		position: relative;

		& .preview__image--wrapper {
			display: block;
		}

		& .play__icon--wrapper {
			position: absolute;
			bottom: 0;
			left: 0;
			width: fit-content;
			background-color: black;

			& .icon--play {
				margin: 7px 0px 3px 7px;
			}
		}
	}

	.video__content {
		& .headline-indicator {
			display: none;
		}

		@media (min-width: $desktop-breakpoint) {
			height: fit-content;
		}

		& .headline {
			padding-top: 5px;
		}
	}

	.video__content > :first-child {
		padding-top: 5px;
	}

	.video__content > * {
		padding-bottom: 5px;
	}

	.video__article-wrapper > :first-child {
		height: fit-content;
	}

	@media (min-width: $desktop-breakpoint) {
		margin-bottom: 0;
		padding: 0;
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:287");