@use "sass:meta" as ---p0dxu0oa6o;.interactive-graphic {
	display: none;

	iframe {
		border: 0;
		width: 100%;
		height: 0;
	}
}

.interactive-graphic.loadingComplete {
	display: block;
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:290");