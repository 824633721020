@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.breaking-story-group--secondary-stories {
	align-self: end;
}

@media (max-width: ($desktop-breakpoint - 1)) {
	.grid-item.breaking-story-group--image {
		grid-column-start: 1;
		grid-row-start: 1 !important; /* push image to the top */
	}
}

// IE 11 Grid positioning
.breaking-story-group--image {
	grid-row-start: 1;
	grid-column-start: 7;
}

.breaking-story-group {
	grid-row-start: 2;
	grid-column-start: 1;

	@media (min-width: $desktop-breakpoint) {
		grid-row-start: 1;

		.primary-story__teaser {
			.headline {
				padding-right: 10%;
			}

			.standfirst {
				padding-right: 20%;
			}
		}
	}
}

.breaking-story-group--secondary-stories {
	grid-row-start: 4;
	grid-column-start: 1;

	.stacked-secondary-story--span-1, .stacked-secondary-story--span-2, .stacked-secondary-story--span-3, .stacked-secondary-story--span-4 {
		border-color: oColorsMix(black, paper, 20);

		@media (min-width: $desktop-breakpoint) {
			&:not(.stacked-secondary-story__force-grid-on-desktop, .separator--solid) {
				width: 70%;
			}
		}
	}

	@media (min-width: $desktop-breakpoint) {
		&.grid-item--span-6 {
			grid-row-start: 2;
		}
	}

	.grid__story-group-wrapper--secondary {
		padding: 0 $horizontal-spacing;

		@media (min-width: $desktop-breakpoint) {
			padding: 0;
		}
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:302");