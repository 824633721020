@use "sass:meta" as ---p0dxu0oa6o;@import '@financial-times/o-colors/main';

.story-group {
	display: grid;
	grid-template-rows: 1fr;
	height: 100%;

	.separator--dotted {
		margin-top: $vertical-spacing;
	}
}

@for $i from 1 through 4 {
	.story-group--column-start-#{$i} {
		@media (min-width: $desktop-breakpoint) {
			grid-column-start: $i;
		}
	}
}

@for $i from 1 through 10 {
	.story-group--row-start-#{$i} {
		@media (min-width: $desktop-breakpoint) {
			grid-row-start: $i;
		}
	}
}

.slice.top-stories-slice {
	.story-group {
		.story-group__article--secondary,
		.story-group__separator,
		.primary-story__teaser,
		.primary-story__image {
			padding: 0 $horizontal-spacing;
		}

		@media (max-width: ($desktop-breakpoint - 1)) {
			&:not(.story-group--extra-small) {
				.story-group__article-wrapper + .story-group__article-wrapper {
					.story-group__article--secondary {
						.grid {
							padding-top: $vertical-spacing;
						}
					}
				}

				.story-group__article-wrapper {
					&:nth-child(2) {
						margin-top: $vertical-spacing;
						.story-group__article--secondary .grid {
							padding-top: 0;
						}
					}

					.story-group__article--secondary {
						.grid {
							grid-gap: 0;
						}
					}

					&:last-child {
						margin-bottom: $vertical-spacing;

						.story-group__article--secondary {
							.grid {
								height: auto;
							}
						}
					}

					.story-group__article--secondary {
						flex: none;

						.grid {
							border-left: solid oColorsByName('black-10') 1px;
							padding: 0 $horizontal-spacing;
							margin-left: 0;
							display: block;
						}

						.metadata__live-blog {
							margin-top: $vertical-spacing;
						}

						.metadata:not(.metadata__live-blog) {
							display: none;
						}
					}

					.story-group__separator {
						display: none;
					}
				}

				&.story-group--featured {
					.story-group__article-wrapper {
						.story-group__article--secondary {
							.grid {
								border-color: oColorsMix(black, paper, 20);
							}
						}
					}
				}
			}
		}
	}

	&.story-group-named-slice:not(.story-group) {
		.primary-story__image {
			padding: 0;
		}
		.primary-story__teaser {
			padding: 0 $horizontal-spacing;
		}
	}
}

.slice.top-stories-slice:not(.story-group-named-slice) {
	.story-group:not(.story-group--featured) {
		background-color: oColorsMix(white, paper, 40);
		border-top: 1px solid oColorsByName('black-10');
	}

	.story-group {
		// make image full-bleed in teasers when tinted background is applied
		.primary-story__image {
			padding: 0;
		}

		// only apply for primary story
		.story-group__article--lead {
			.metadata {
				padding-left: $horizontal-spacing;
			}
		}

		// only apply for primary story when no secondary story in the story group
		.story-group__article-wrapper:only-child {
			.story-group__article--lead {
				padding-bottom: 1rem;
			}
		}

		// secondary teasers should normally have a padding
		.story-group__article--secondary {
			padding-right: $horizontal-spacing;
			padding-bottom: 0;
			padding-left: $horizontal-spacing;
		}

		.story-group__separator {
			padding-left: $horizontal-spacing;
			padding-right: $horizontal-spacing;
		}
	}
}

.story-group.story-group--featured {
	background-color: oColorsByName('wheat');
	.primary-story__teaser {
		text-align: center;
		padding-left: $horizontal-spacing;
		padding-right: $horizontal-spacing;
	}

	// Having a darker background, the borders need a darker color.
	.separator--solid,
	.story-group__article,
	.story-group__article--featured {
		border-color: oColorsMix(black, paper, 20);
	}

	.story-group__article--lead {
		.metadata {
			padding-right: $horizontal-spacing;
			text-align: center;
		}

		.metadata__opinion {
			justify-content: center;
		}
	}
}

.story-group--full-width-4 {
	@media (min-width: $desktop-breakpoint) {
		grid-column: span 4;
	}
}

.story-group--full-width-3 {
	@media (min-width: $desktop-breakpoint) {
		grid-column: span 3;
	}
}

.story-group--full-width-2 {
	@media (min-width: $desktop-breakpoint) {
		grid-column: span 2;
	}
}

.story-group__article-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.separator--solid {
		margin: $vertical-spacing 0;
	}

	.story-group__separator--hidden {
		display: block;
	}

	&:last-child {
		.story-group__separator--hidden {
			display: none;
		}
	}

	@media (min-width: $desktop-breakpoint) {
		.story-group__separator--hidden {
			display: none;
		}
	}
}

.story-group__article {
	flex: 1 1 auto;

	@media (min-width: $desktop-breakpoint) {
		border-right: solid oColorsByName('black-10') 1px;

		&:last-child {
			border-right: none;
		}
	}

	.story-group--end-of-row & {
		border-right: none;
	}
}

@media (min-width: $desktop-breakpoint) {
	.slice.top-stories-slice {
		.story-group {
			.story-group__separator,
			.primary-story__teaser,
			.primary-story__image {
				padding: 0;
			}
		}

		&.story-group-named-slice:not(.story-group) {
			.breaking-story-group,
			.story-group:not(.story-group--featured),
			.story-group-stacked:not(.story-group--featured) {
				.primary-story__teaser {
					padding: 0;
				}
				.story-group--column-start-1 {
					.story-group__article--secondary {
						padding: 0;
					}
				}
			}
		}
	}
	.slice.top-stories-slice:not(.story-group-named-slice) {
		.story-group:not(.story-group--featured) {
			border-top: none;
		}

		.story-group {
			.primary-story__teaser {
				padding: 0 $horizontal-spacing;
			}
		}

		// only apply top padding for the medium and large size slot primary story on desktop view
		.story-group--full-width-2,
		.story-group--full-width-3 {
			.primary-story__teaser {
				padding-top: $vertical-spacing;
			}
		}
	}

	.story-group__article,
	.story-group__separator {
		padding: 0 $horizontal-spacing;

		.story-group--column-start-1 & {
			padding-left: 0;
			padding-right: $horizontal-spacing;
		}

		.story-group--end-of-row & {
			padding-left: $horizontal-spacing;
			padding-right: 0;
		}

		.story-group--column-start-1.story-group--end-of-row & {
			padding: 0;
		}
	}

	// Add a space between primary story and secondary stories on Large/Medium StroyGroup
	// when the articles are more than 2 (:nth-last-child(n+3))
	// Don't apply this margin when the PrimaryStory is not rendered
	.story-group:not(.story-group--skip-primary-story) {
		.grid--2-columns {
			.story-group__article-wrapper:first-child:nth-last-child(n + 3) {
				.story-group__article {
					margin-bottom: $vertical-spacing * 2;
				}
			}
		}

		.grid--3-columns {
			.story-group__article-wrapper:first-child:nth-last-child(n + 3) {
				.story-group__article {
					margin-bottom: 2rem;
				}
			}
		}
	}

	.top-stories-slice {
		.grid--2-columns,
		.grid--3-columns {
			.story-group__article-wrapper:first-child:nth-last-child(n + 3) {
				.story-group__article {
					margin-bottom: $vertical-spacing * 2;
				}
			}
		}
	}
}

// Hack: fixing bugs before ramp up release
// We need to fix these bugs properly after Percy is available
@media (min-width: $desktop-breakpoint) {
	.slice.story-group-named-slice {
		.story-group.story-group--featured {
			.story-group__article.story-group__article--featured {
				padding-bottom: $vertical-spacing;
			}
			.story-group__separator {
				padding-left: $horizontal-spacing;
				padding-right: $horizontal-spacing;
			}
		}
		// fix Large StoryGroup in top slice separator right/left padding
		.layout-desktop--full-height[data-trackable-context-storygroup-size="large"] {
			.story-group:not(.story-group--featured) {
				.story-group__separator {
					padding: 0 $horizontal-spacing;
				}
				.story-group--column-start-1 .story-group__separator {
					padding-left: 0;
					padding-right: $horizontal-spacing;
				}
				.story-group--end-of-row .story-group__separator {
					padding-left: $horizontal-spacing;
					padding-right: 0;
				}
				.story-group--column-start-1.story-group--end-of-row .story-group__separator {
					padding: 0;
				}
			}
		}
	}
}

;@include ---p0dxu0oa6o.load-css("sass-embedded-legacy-load-done:299");